import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHospital, faHeartPulse, faBrain, faXRay } from '@fortawesome/free-solid-svg-icons'
import useWindowDimensions from '../lib/helper.js'

const ServicesProvided = () => {

   const { height, width } = useWindowDimensions()

   return (
      <div className='grid servicesProvided-wrapper'>
         <div className='row servicesProvided'>
            <div className='col-12 servicesProvided-title'>
               <h2>Areas of Expertise</h2>
               <hr></hr>
            </div>

            {/* Trauma Surgery & Surgery Critical Care */}
            <div className='col-lg-6 col-md-12 text-right'>
               <div className='expertise-title'>
                  <FontAwesomeIcon icon={faHospital} />
                  <span style={{ marginLeft: '20px' }}>
                     Trauma Surgery
                  </span>
                  <p>I have a nearly two decades of experience as a trauma surgeon where I have worked in various Level I and II trauma centers. My research expertise using statistical programs is incorporated in examining national databases for trauma clinical research.</p>
               </div>
            </div>
            <div className='col-lg-6 col-md-12'>
               <div className='expertise-title'>
                  <FontAwesomeIcon icon={faHeartPulse} />
                  <span style={{ marginLeft: '20px' }}>
                     Surgery Critical Care
                  </span>
                  <p>Boarded in Surgical Critical Care since 2018.</p>
               </div>
            </div>

            {/* Vertical Spacer */}
            {width > 501 &&
               <div className='col-12'>
                  <br></br>
               </div>
            }

            {/* Acute Care & Neuro Critical Care */}
            <div className='col-lg-6 col-md-12 text-right'>
               <div className='expertise-title'>
                  <FontAwesomeIcon icon={faXRay} />
                  <span style={{ marginLeft: '20px' }}>
                     Acute Care Surgery
                  </span>
                  <p>Board certified in General Surgery with a 20 year experience with both elective and emergency general surgery (EGS).</p>
               </div>
            </div>
            <div className='col-lg-6 col-md-12'>
               <div className='expertise-title'>
                  <FontAwesomeIcon icon={faBrain} />
                  <span style={{ marginLeft: '20px' }}>
                     Neuro Critical Care
                  </span>
                  <p>Board Certified in Neuro Critical Care since 2021 through the <a target='_blank' href='https://www.abpn.com/'>American Board of Psychiatry and Neurology (ABPN)</a> and the <a target='_blank' href='https://www.theaba.org/'>American Board of Anesthesiology (ABA)</a>. Obtaining this board complements and enhances my critical care experience in taking care of complex brain injured patients.</p>
               </div>
            </div>

         </div>
      </div>
   )
}

export default ServicesProvided