import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserDoctor } from '@fortawesome/free-solid-svg-icons'
import useWindowDimensions from '../lib/helper.js'


import React from 'react'

const AboutMe = () => {
   const { height, width } = useWindowDimensions()

   return (
      <>
         <div id='#about' className='grid aboutMe-wrapper'>
            <div className='row d-flex justify-content-center'>

               {/* Large Screens */}
               {width > 901 &&
                  <>
                     <div className='col-8'>
                        <h1>About Me</h1>
                        <p>
                           Once I graduated from Suny Downstate Medical School in 1997 and finished my Navy Internship
                           in Surgery in 1998, I then served aboard Navy amphibious ships based out of Sasebo Japan. I
                           then finished my residency in surgery at Allegheny General Hospital in 2004 which was followed
                           by working as general surgeon on an aircraft carrier based out of Norfolk, Virgina.
                        </p>
                        <p>
                           For the next two decades I continued to work and specialize in trauma surgery where I worked
                           in various Level 1 and 2 trauma centers. I completed my Surgical Critical Care fellowship in 2017
                           as well as obtained its corresponding board certification. Most recently, I expanded my
                           specialty by obtaining board certification in Neuro Critical care in 2021.
                        </p>
                        <p>
                           My research interests involve areas in trauma such as blunt splenic injury, geriatric trauma and
                           blunt chest injury with rib fractures with my research being presented at national meetings.
                        </p>
                     </div>
                     <div className='col-4 aboutMe-iconWrapper'>
                        <span className='aboutMe-icon'>
                           <FontAwesomeIcon icon={faUserDoctor} />
                        </span>
                     </div>
                  </>
               }

               {/* Mobile Screens */}
               {width < 901 &&
                  <>
                     <div className='col-12 align-self-center text-center'>
                        <h1>About Me</h1>
                     </div>
                     <div className='col-6 aboutMe-iconWrapper'>
                        <span className='aboutMe-icon'>
                           <FontAwesomeIcon icon={faUserDoctor} />
                        </span>
                     </div>
                     <div className='col-12' style={{ marginBottom: '20px' }}>
                        <p>
                           Once I graduated from Suny Downstate Medical School in 1997 and finished my Navy Internship
                           in Surgery in 1998, I then served aboard Navy amphibious ships based out of Sasebo Japan. I
                           then finished my residency in surgery at Allegheny General Hospital in 2004 which was followed
                           by working as general surgeon on an aircraft carrier based out of Norfolk, Virgina.
                        </p>
                        <p>
                           For the next two decades I continued to work and specialize in trauma surgery where I worked
                           in various Level 1 and 2 trauma centers. I completed my Surgical Critical Care fellowship in 2017
                           as well as obtained its corresponding board certification. Most recently, I expanded my
                           specialty by obtaining board certification in Neuro Critical care in 2021.
                        </p>
                        <p>
                           My research interests involve areas in trauma such as blunt splenic injury, geriatric trauma and
                           blunt chest injury with rib fractures with my research being presented at national meetings.
                        </p>
                     </div>
                  </>
               }
            </div>
         </div>
      </>
   )
}

export default AboutMe