import React from 'react'

import ServicesProvided from './components/MedicalServicesProvided';
import AboutMe from './components/AboutMe';

import useWindowDimensions from './lib/helper'
import CV from './static/CV/2023CVejeremitsky.pdf'

import './App.css';

function App() {
   const { height, width } = useWindowDimensions()

   const scrollToAboutSection = () => {
      console.log('scrolling')
      let aboutTitle = document.getElementById('about')

      aboutTitle.scrollIntoView()
   }

   return (
      <div style={{ position: 'relative', paddingBottom: '100px' }}>
         {/* NavBar */}
         <div className="grid-fluid">
            <div className='navBar grid-fluid d-flex justify-content-center'>
               <div className='row titleRow'>
                  <div className='col-4 d-flex justify-content-right'>
                     <div className='title'>
                     </div>
                  </div>

                  <div className='col-2'></div>

                  {width < 1000 &&
                     <div className='col-6' style={{ position: 'relative' }}>

                        <div className='navBar-buttons'>
                           {/* <a className='navBar-contentLink' href={CV}>My CV</a> */}
                           <a className='navbar-contactLink' onClick={() => window.location = 'mailto:Maxiwell@myyahoo.com'}>Contact</a>
                        </div>
                     </div>
                  }

                  {/* Every Other Screen */}
                  {width > 1000 &&
                     <div className='col-6' style={{ position: 'relative' }}>
                        <div className='navBar-buttons'>
                           <a className='navBar-contentLink' onClick={scrollToAboutSection} href='#about' >About Me</a>
                           {/* <a className='navBar-contentLink' href={CV} target='_blank'>My Curriculum Vitae</a> */}
                           <a className='navbar-contactLink' onClick={() => window.location = 'mailto:Maxiwell@myyahoo.com'}>Contact Today!</a>
                        </div>
                     </div>
                  }

               </div>
            </div>
         </div>


         {/* Intro Image */}
         <div className='introImage' style={{ position: 'relative' }}>
            <div className='image-overlay'>
               Surgical & Medical Consulting
            </div>
            <div className='image-overlay-subtitle'>
               <i>Dr. Elan Jeremitsky, MD, FACS</i>
            </div>
         </div>

         <br />

         {/* First Content Block */}
         {/* <div className="grid-fluid servicesProvided-bgImage"> */}
         <div className="grid-fluid servicesProvided-bgWrapper">
            <div className='row'>

               {/* Vertically Centered content */}
               <div className='col-12 d-flex justify-content-center'>
                  <ServicesProvided />
               </div>

            </div>
         </div>

         {/* Second Content Block */}
         <div className="grid-fluid">
            <div className='row'>

               {/* Vertically Centered content */}
               <div id='about' className='col-12 d-flex justify-content-center'>
                  <AboutMe />
               </div>

            </div>
         </div>

         {width < 700 &&
            <div className='pageFooter text-center'>
               Dr. Elan Jeremitsky <br /> 412-445-2500 <br /> &copy; 2023 Maxiwellspring.com
            </div>
         }

         {width > 700 &&
            <div className='pageFooter'>
               &emsp; Dr. Elan Jeremitsky &emsp; | &emsp; &copy; 2023 Maxiwellspring.com &emsp; | &emsp; 412-445-2500
            </div>
         }

      </div>
   );
}

export default App;
